<template>

  <v-card flat>
    <v-card-title class="d-flex justify-space-between">
      <span>Помещение</span>
      <v-btn icon @click="close"><v-icon>mdi-window-close</v-icon></v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field :value="item.type" dense label="Тип" readonly />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field :value="item.block" dense label="Блок" readonly />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field :value="item.floor" dense label="Этаж" readonly />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field :value="item.name" dense label="Номер" readonly />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field :value="item.area" dense label="Площадь" readonly />
        </v-col>
        <v-col cols="12">
          <v-text-field :value="item.bti" dense label="Номер БТИ" readonly />
        </v-col>
        <v-col cols="12">
          <v-text-field :value="item.description" dense label="Описание" readonly />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

</template>

<script>
export default {
  name: 'FacilityFlatView',
  computed: {
    item: function () {
      return this.$store.state.rn.params.item
    }
  },
  methods: {
    close () {
      this.$store.commit('rn/CLOSE_VIEW')
    }
  }
}
</script>

<style scoped>

</style>
